import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreUiModule } from '../core/core-ui.module';
import { RouterModule } from '@angular/router';
import { MobileRoutes } from './mobile.routes';
import { MobileViewComponent } from './pages/mobile-view/mobile-view.component';
import { SortPipe } from './pipes/sort.pipe';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
    declarations: [MobileViewComponent, SortPipe, SafeHtmlPipe],
    imports: [
        CommonModule,
        YouTubePlayerModule,
        CoreUiModule,
        BrowserAnimationsModule,
        PinchZoomModule,
        RouterModule.forChild(MobileRoutes)
    ],
    providers: []
})
export class MobileViewModule {}
