import { Pipe, PipeTransform } from '@angular/core';
import { Section } from 'src/modules/shared/model/section';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform(value: Section[]): Section[] {
        return value.sort((a: Section, b: Section) => a.section_order - b.section_order);
    }
}
