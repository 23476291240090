import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { LoginGuard } from '../shared/guards/login.guard';
import { MainComponent } from './components/main/main.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { MobileViewComponent } from '../mobile/pages/mobile-view/mobile-view.component';

const routes: Routes = [
    {
        path: 'pozorisni-muzej',
        component: MainComponent,
        children: [
            {
                path: 'statistika',
                loadChildren: () => import('./../statistics/statistics.module').then((m) => m.StatisticsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'informacije',
                loadChildren: () => import('./../contact/contact.module').then((m) => m.ContactModule)
            },
            {
                path: 'izlozbe',
                loadChildren: () => import('./../exhibition/exhibition.module').then((m) => m.ExhibitionModule)
            }
        ]
    },
    { path: 'prijava', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'mobilni-prikaz', component: MobileViewComponent },
    { path: 'zabranjen-pristup', component: AccessDeniedComponent },
    { path: '', redirectTo: 'pozorisni-muzej/izlozbe/pocetna', pathMatch: 'full' },
    { path: 'policy/:language', component: PolicyComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
