import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExhibitionListComponent } from './pages/exhibition-list/exhibition-list.component';
import { CoreUiModule } from '../core/core-ui.module';
import { ExhibitionRoutes } from './exhibition.routes';
import { RouterModule } from '@angular/router';
import { ExhibitionViewComponent } from './pages/exhibition-view/exhibition-view.component';
import { ExhibitionFormComponent } from './pages/exhibition-form/exhibition-form.component';
import { PageDetailsComponent } from './components/page-details/page-details.component';
import { ExhibitionDetailsComponent } from './components/exhibition-details/exhibition-details.component';
import { PageManagementComponent } from './components/page-management/page-management.component';
import { BasicCommandsComponent } from './components/basic-commands/basic-commands.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { NgxWidgetGridModule } from 'ngx-custom-greenenvy-widget-grid';
import { SharedModule } from 'primeng/api';
import { SortPipe } from './pipes/sort.pipe';
import { ExhibitionFrontPageComponent } from './pages/exhibition-front-page/exhibition-front-page.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PageViewComponent } from './components/page-view/page-view.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PageContentComponent } from './components/page-content/page-content.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BackgroundDialogComponent } from './components/background-dialog/background-dialog.component';
import { ScrollableTextDirective } from './directives/scrollable-text.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgxMasonryModule } from 'ngx-masonry';
import { GalleryManagementComponent } from './components/gallery-management/gallery-management.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { PageQrCodeComponent } from './components/page-qr-code/page-qr-code.component';

@NgModule({
    declarations: [
        ExhibitionListComponent,
        ExhibitionViewComponent,
        ExhibitionFormComponent,
        PageDetailsComponent,
        ExhibitionDetailsComponent,
        PageManagementComponent,
        BasicCommandsComponent,
        UploadDialogComponent,
        SortPipe,
        ExhibitionFrontPageComponent,
        PageViewComponent,
        PageContentComponent,
        BackgroundDialogComponent,
        ScrollableTextDirective,
        SafeHtmlPipe,
        GalleryManagementComponent,
        PageQrCodeComponent
    ],
    imports: [
        CommonModule,
        CoreUiModule,
        RouterModule.forChild(ExhibitionRoutes),
        NgxWidgetGridModule,
        SharedModule,
        ConfirmDialogModule,
        QRCodeModule,
        CKEditorModule,
        NgxMasonryModule,
        YouTubePlayerModule
    ]
})
export class ExhibitionModule {}
