import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';

@Component({
    selector: 'app-page-qr-code',
    templateUrl: './page-qr-code.component.html',
    styleUrls: ['./page-qr-code.component.scss']
})
export class PageQrCodeComponent implements OnInit {
    public page$!: Observable<Page | undefined>;
    url: string | null = null;
    private exhibitionId: number | null = null;

    constructor(public store: Store) {}

    ngOnInit(): void {
        this.exhibitionId = this.store.selectSnapshot((state) => state.exhibition.id);
        this.store.select(ExhibitionState.page).subscribe((page) => {
            if (page) {
                this.url = `${window.origin}/mobilni-prikaz?izlozba=${this.exhibitionId}&stranica=${page.page_number}`;
            }
        });
    }
}
