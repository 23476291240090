/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExhibitionService } from '../../services/exhibition.service';
import { Exhibition } from 'src/modules/shared/model/exhibition';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-mobile-view',
    templateUrl: './mobile-view.component.html',
    styleUrls: ['./mobile-view.component.scss'],
    animations: [trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('500ms 0s', style({ opacity: 1 }))])])]
})
export class MobileViewComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    @ViewChildren('pageContainer') pageContainers!: QueryList<ElementRef>;
    public loaded: boolean = false;
    public scrolled: boolean = false;
    public exhibition!: Exhibition;
    public exhibitionPages: Page[] = [];
    public currentPage: number = 0;
    public sidebarVisible: boolean = false;
    public selectedSection: Section | null = null;
    public currentOrientation: string = '';
    public windowWidth: number = 0;
    private exhibitionId: number = 0;
    private pageId: number = 0;

    constructor(private activatedRoute: ActivatedRoute, private exhibitionService: ExhibitionService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.readQueryParams();
        this.updateOrientation();
        window.addEventListener('orientationchange', () => this.updateOrientation(true));
    }

    ngOnDestroy(): void {
        window.removeEventListener('orientationchange', () => this.updateOrientation());
    }

    ngAfterViewChecked() {
        this.windowWidth = window.innerWidth;
        if (!this.scrolled) {
            this.scrollToDiv(`page-${this.pageId}`);
        }
    }

    onScroll(): void {
        const scrollContainer = this.scrollContainer.nativeElement;
        const scrollPosition = scrollContainer.scrollTop;
        const pageOffsets = this.calculatePageOffsets();

        let currentPageIndex = 0;
        for (let i = 0; i < pageOffsets.length; i++) {
            if (scrollPosition >= pageOffsets[i] && (i === pageOffsets.length - 1 || scrollPosition < pageOffsets[i + 1])) {
                currentPageIndex = i;
                break;
            }
        }

        if (currentPageIndex !== this.currentPage) {
            this.currentPage = currentPageIndex;
        }
    }

    calculatePageOffsets(): number[] {
        const pageOffsets: number[] = [];
        const scrollContainer = this.scrollContainer.nativeElement;
        let offset = 0;

        const pages = Array.from(scrollContainer.children);
        pages.forEach((page: any) => {
            pageOffsets.push(offset);
            offset += page.offsetHeight;
        });

        return pageOffsets;
    }

    readQueryParams() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.exhibitionId = params?.izlozba;
            this.pageId = params?.stranica;
            this.loadExhibition();
        });
    }

    loadExhibition() {
        this.exhibitionService.getExhibitionDetail(this.exhibitionId).subscribe((exhibition) => {
            this.exhibition = exhibition;
            this.exhibitionPages = exhibition.pages.sort((a: Page, b: Page) => a.page_number - b.page_number);
            this.loaded = true;
        });
    }

    scrollToDiv(idToScroll: string) {
        const targetDiv = document.getElementById(idToScroll);
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'auto', block: 'start' });
            this.scrolled = true;
        }
    }

    toggleSidebar() {
        this.sidebarVisible = !this.sidebarVisible;
    }

    scrollToElement(elementId: string): void {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            this.sidebarVisible = false;
        }
    }

    selectSection(section: Section) {
        console.log(section);
        this.selectedSection = section;
    }

    closeOverlay() {
        this.selectedSection = null;
    }

    calculateOrientation() {
        if (screen.orientation.type?.includes('landscape')) {
            this.currentOrientation = 'landscape';
        } else if (screen.orientation.type.includes('portrait')) {
            this.currentOrientation = 'portrait';
        }
    }

    updateOrientation(redraw?: boolean) {
        this.calculateOrientation();
        if (redraw) {
            this.cdr.detectChanges();
        }
    }

    getPageBackground(page: Page) {
        this.calculateOrientation();
        if (this.currentOrientation === 'landscape' && page.background_image_landscape?.file) {
            return page.background_image_landscape?.file;
        } else if (this.currentOrientation === 'portrait' && page.background_image_portrait?.file) {
            return page.background_image_portrait?.file;
        } else if (this.currentOrientation === 'landscape' && page.background_image_portrait?.file) {
            return page.background_image_portrait?.file;
        } else {
            return page.background_image_landscape?.file;
        }
    }

    getSectionBackground(section: Section) {
        this.calculateOrientation();
        if (this.currentOrientation === 'landscape' && section.background_image_landscape?.file) {
            return section.background_image_landscape?.file;
        } else if (this.currentOrientation === 'portrait' && section.background_image_portrait?.file) {
            return section.background_image_portrait?.file;
        } else if (this.currentOrientation === 'landscape' && section.background_image_portrait?.file) {
            return section.background_image_portrait?.file;
        } else {
            return section.background_image_landscape?.file;
        }
    }

    getVideoId(youtubeUrl: string | undefined) {
        if (youtubeUrl) {
            let retVal: RegExpMatchArray | null = null;
            const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
            retVal = youtubeUrl.match(rx);
            if (retVal !== null) {
                return retVal[1];
            }
        }
        return '';
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.windowWidth = window.innerWidth;
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event: Event) {
        this.windowWidth = window.innerWidth;
    }

    youtubeReady(e: any) {
        e.target.playVideo();
    }
}
