import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Exhibition } from 'src/modules/shared/model/exhibition';

@Injectable({
    providedIn: 'root'
})
export class ExhibitionService {
    constructor(public http: HttpClient) {}

    getExhibitionDetail(exhibitionId: number): Observable<Exhibition> {
        return this.http.get<Exhibition>(`api/mobile/exhibition/${exhibitionId}`);
    }
}
