<div *ngIf="page; else loadingPage">
    <div class="card content" style="background-color: #fae7cc">
        <div *ngIf="page.gallery === false" class="dashboard-container">
            <ngx-widget-grid
                #grid
                [ngStyle]="{
                    'background-color': page['background_color'] !== null ? page['background_color'] : 'transparent',
                    'background-image':
                        page['background_image_landscape'] !== null ? 'url(' + page['background_image_landscape'].file + ')' : 'none',
                    'background-size': 'cover'
                }"
                [rows]="page.row_number"
                [columns]="page.column_number"
                [highlightNextPosition]="false"
                [showGrid]="editable"
            >
                <ng-container *ngFor="let section of sections | sort : 'section_order'; let i = index">
                    <ngx-widget
                        [ngClass]="
                            editable === true
                                ? section.section_order === selectedSection
                                    ? 'ngxWidgetBorderSelected'
                                    : 'ngxWidgetBorder'
                                : 'ngxWidgetNoBorder'
                        "
                        [position]="section.position"
                        (positionChange)="positionChanged($event, section.section_order)"
                        [movable]="editable"
                        [resizable]="editable"
                        [resizeDirections]="resizeDirections"
                        (click)="changeSelection(section.section_order, section.content?.content_type)"
                        (contextMenu)="changeSelection(section.section_order, section.content?.content_type)"
                        [ngStyle]="{
                            'background-color': section['background_color'] !== null ? section['background_color'] : 'transparent',
                            'background-image':
                                section['background_image_portrait'] !== null
                                    ? 'url(' + section['background_image_portrait'].file + ')'
                                    : section['background_image_landscape'] !== null
                                    ? 'url(' + section['background_image_landscape'].file + ')'
                                    : 'none',
                            'background-size': 'cover'
                        }"
                        #widgetWrapper
                    >
                        <img class="center" *ngIf="section.content?.content_type === 'image'" [src]="section.content?.file?.file" />
                        <audio class="centerAudio" *ngIf="section.content?.content_type === 'audio'" controls>
                            <source [src]="section.content?.file?.file" />
                        </audio>
                        <div *ngIf="section.content?.content_type === 'video'" #youtubeWrapper style="width: 100%; height: 100%">
                            <youtube-player class="content" [videoId]="getVideoId(section.content?.file?.name)" suggestedQuality="highres">
                            </youtube-player>
                        </div>
                        <div class="download" *ngIf="section.content?.content_type === 'download'">
                            <a [href]="section.content?.file?.file">
                                <img class="centerDownload" src="/assets/images/icons/download-icon.png" />
                            </a>
                        </div>
                        <div *ngIf="section.content?.content_type === 'text' && selectedSection === section.section_order">
                            <ckeditor
                                #textEditor
                                [ngStyle]="{ overflow: 'auto' }"
                                [editor]="editor"
                                [config]="config"
                                [data]="section.content.text_content"
                                ngClass="ck-content"
                                (ready)="onReady($event, section.content.text_content)"
                            ></ckeditor>
                        </div>

                        <div *ngIf="section.content?.content_type === 'text' && selectedSection !== section.section_order">
                            <div class="ck-content custom-content" [innerHTML]="section.content.text_content | safeHtml"></div>
                        </div>
                    </ngx-widget>
                </ng-container>
            </ngx-widget-grid>
        </div>
        <div *ngIf="page.gallery === true" class="gallery">
            <ngx-masonry [options]="{ gutter: 10 }" [ordered]="true">
                <div ngxMasonryItem class="masonry-item" *ngFor="let item of getSectionInOrderForGaller(page.sections)">
                    <img
                        (click)="changeSelection(item.section_order, item.content?.content_type)"
                        *ngIf="item.section_order !== selectedSection"
                        class="galleryImage"
                        [src]="item.content?.file?.file"
                    />
                    <img *ngIf="item.section_order === selectedSection" class="galleryImageWithShadow" [src]="item.content?.file?.file" />
                </div>
            </ngx-masonry>
        </div>
    </div>
</div>

<ng-template #loadingPage>
    <div class="card" style="background-color: #fae7cc">
        <div class="dashboard-container">
            <div class="dashboard-page"></div>
        </div>
    </div>
</ng-template>
